
document.addEventListener('DOMContentLoaded', (event) => {
    Menu.setHeight();
    Menu.setScrolling();
    Menu.listenOpen();

    Cookies.init();

    FormValidation.vivalid();
    FormValidation.file();
    Slider.initWithThumbs();
    PhotoSwipePlugin.init();

    AOS.init({
        duration: 1000,
        once: true,
        disable: function() {
            const maxWidth = 1025;
            return window.innerWidth < maxWidth;
        }
    });

    objectFitImages();

    let doneOnce = false;
    window.addEventListener('scroll', function () {
        let isOnViewPort = isScrolledIntoView.init( document.getElementsByClassName('js-number-count')[0] );

        if (isOnViewPort && !doneOnce) {
            Counter.init(document.getElementsByClassName('js-number-count'));
            doneOnce = true;
        }
    });

    MicroModal.init({
        disableScroll: true,
        awaitCloseAnimation: true
    });
});
