
class Slider {

    /**
     * Init tiny slider with thumbs.
     * "js-gallery-main" is tiny slider which presents big picture with selected thumb.
     * "js-gallery-thumbs" is tiny slider which presents thumbnails.
     */
    static initWithThumbs() {
        const mainGalleries = document.getElementsByClassName('js-sub-header-carousel'),
            thumbsGalleries = document.getElementsByClassName('js-sub-header-carousel-nav');

        let newMainG, newThumbsG;

        if (mainGalleries && thumbsGalleries && ( mainGalleries.length === thumbsGalleries.length ) ) {
            for (let i = 0; i < mainGalleries.length; ++i) {

                newMainG = tns({
                    loop: false,
                    container: mainGalleries[i],
                    navContainer: thumbsGalleries[i],
                    items: 1,
                    navAsThumbnails: true,
                    mouseDrag: false,
                    controls: false
                });

                newThumbsG = tns({
                    loop: false,
                    container: thumbsGalleries[i],
                    items: 3,
                    mouseDrag: true,
                    nav: false,
                    gutter: 15,
                    responsive: {
                        778: {

                        }
                    },
                    controlsContainer: document.getElementById('sub-header-carousel-nav-controls')
                });
            }
        }
    }
}
