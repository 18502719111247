
class Counter {

    static init(elements) {

        for (let j = 0; j < elements.length; ++j) {
            Counter.start(elements[j]);
        }
    }

    static start(el) {
        let i = 0;
        let numToCount = parseInt(el.getAttribute('data-number'));
        let iterator = parseInt(el.getAttribute('data-iterator'));
        el.innerHTML = i;

        let oneStep = function() {
            el.innerHTML = i;
            if ( i >= numToCount ) clearInterval(startInterval);
            i = i + iterator;
            if ( i > numToCount ) i = numToCount;
        };
        let startInterval = setInterval(oneStep, 1);
    }
}
