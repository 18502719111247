
class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const topBar = document.getElementById('top-bar');
        const menu = document.getElementById('menu-container');
        body.style.paddingTop = `${topBar.offsetHeight + menu.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let topBar = document.getElementById('top-bar');
            let menu = document.getElementById('menu-container');
            let currentScrollPos = window.pageYOffset;

            // if menu's height is bigger than scrolled area start scrolling
            if (topBar.offsetHeight < currentScrollPos) {
                menu.classList.add('menu-container--is-scrolled');
                topBar.classList.add('top-bar--scrolled');

                if (currentScrollPos > prevScrollPos) {
                    menu.classList.remove('menu-container--scrolled-top');
                } else {
                    menu.classList.add('menu-container--scrolled-top');
                }
            } else {
                menu.classList.remove('menu-container--is-scrolled');
                menu.classList.remove('menu-container--scrolled-top');
                topBar.classList.remove('top-bar--scrolled');
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav-opened')[0];

        checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                bodyScrollLock.disableBodyScroll(navList);
               //document.body.style.overflow = 'hidden';
            } else {
                bodyScrollLock.enableBodyScroll(navList);
               //document.body.style.overflow = '';
            }
        });
    }
}
