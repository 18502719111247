class Animations {

    static offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
    }

    static parallaxSlow(parent, children) {
        let parentOffset = this.offset(parent);
        if (window.matchMedia('(min-width: 1024px)').matches) {
            let scroll = (window.scrollY - parentOffset.top)/2;
            if (scroll < 0 ) children.style.transform = `translateY(${-scroll/2}px)`;
        }
    }
}

function initAnimations() {

    const parallaxEls = document.querySelectorAll('.js-parallax-parent');
    if ( parallaxEls.length > 0 ) {
        parallaxEls.forEach( parent => {
            if (parent) {
                let child = parent.querySelector('.js-parallax-child');
                if (child) Animations.parallaxSlow(parent, child);
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', (event) => {
    initAnimations();
});

window.addEventListener("scroll", () => {
    initAnimations();
});
